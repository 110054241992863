/** @jsx jsx */
import { Link } from "gatsby";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Navigation, EffectFade } from "swiper";
import { Col, Container, Row } from "react-bootstrap";
import { SliderTwoData } from "@/data";
import { jsx } from '@emotion/react'

import {mainSlider}  from '../assets/styles/SliderOne.styles'
import { mainSliderTwo }  from '../assets/styles/SliderTwo.styles'

import { 
  sliderHeader,
}  from '../assets/styles/SliderHeader.styles'

SwiperCore.use([Autoplay, Navigation, EffectFade]);
const SliderTwo = () => {
  const mainSlideOptions = {
    slidesPerView: 1,
    loop: true,
    effect: "fade",
    autoplay:{
      delay: 9500,
      disableOnInteraction: false,
    },
    navigation: {
      nextEl: "#main-slider-next",
      prevEl: "#main-slider-prev",
    },
    
  };
  return (
    <section css={[mainSlider,mainSliderTwo]}>
      <Swiper {...mainSlideOptions}>
        {SliderTwoData.map(({ image, title, text, button }, index) => (
          <SwiperSlide key={index}>
            <div
              className="image-layer"
              style={{ backgroundImage: `url(${image})` }}
            ></div>
            <Container css={sliderHeader}>
              <Row>
                <Col lg={12} className="text-left">
                  <h1>{title}</h1>
                  <p>{text}</p>
                  <Link to={button.url}>
                    {button.label}
                  </Link>
                </Col>
              </Row>
              
            </Container>
          </SwiperSlide>
        ))}
        <div className="swiper-button-prev" id="main-slider-prev">
          <i className="fa fa-angle-left"></i>
        </div>
        <div className="swiper-button-next" id="main-slider-next">
          <i className="fa fa-angle-right"></i>
        </div>
      </Swiper>
    </section>
  );
};

export default SliderTwo;
