/** @jsx jsx */
import { Col, Container, Row } from "react-bootstrap";
import { jsx } from '@emotion/react'
import { Fragment } from "react";
import ImgOrigem from '../assets/images/lp/home2/ensino-personalizado.png'

import { 
  boxContent,
}  from '../assets/styles/ContentSection.styles'

const EnsinoPersonzalizado = () => {
  return (
    <Fragment>
      <section css={boxContent}>
        <Container>
          <Row>
            <Col lg={4} md={4} sm={12}>
              <h2><span>Ensino</span></h2>
              <h2>Personalizado</h2>
              <img src={ImgOrigem} alt="" />
            </Col>
            <Col lg={1} md={1} sm={12} />
            <Col lg={6} md={6} sm={12} data-aos-delay={"400"} data-aos="fade-left">
              <p>Somos especialistas em aprovação. Mapeamos os processos de ensino-aprendizagem, desconstruímos e analisamos em profundidade como são desenvolvidas as questões e desafios de avaliações das mais prestigiadas instituições de ensino superior do país e criamos um método exclusivo que transforma potencial em sucesso acadêmico excepcional.</p>
              <p>Concentramos nossa abordagem em diagnosticar individualmente nossos alunos, entendendo quais suas potencialidades e personalizando trilhas de aprendizagem para que cada um trace sua jornada de excelência. A partir daí tudo se transforma em um processo de descoberta guiado por muita disciplina e estratégia. Empreenda uma busca pela grandeza que existe em você, seja PRO.</p>
            </Col>
          </Row>
        </Container>
      </section>
    </Fragment>
  );
};

export default EnsinoPersonzalizado;
