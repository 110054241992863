/** @jsx jsx */
import React, { useState } from "react"
import { Container, Col, Row } from "react-bootstrap"
import { TurmasHome } from "@/data"
import { jsx } from '@emotion/react'

import {
  chooseUsTitle,
  contentBox,
  turmasHeader,
  imgTab
} from "../assets/styles/TurmasTabHome.styles";

const TurmasTabs = () => {
  const [active, setActive] = useState(0);
  const { posts } = TurmasHome;
  return (
    <section css={turmasHeader}>
      <div id="tabs">
        <Container>
          <Row>
            <Col lg={5} />
            <Col lg={7}>
              <ul className='tabs-ul' css={chooseUsTitle}>
                {posts.map(({ titleTab }, index) => (
                  <li
                    key={index}
                    className={`${active === index ? "active" : " "}`}
                  >
                    <a
                      href="#"
                      onClick={e => {
                        e.preventDefault();
                        setActive(index);
                      }}
                    >
                      {titleTab}
                    </a>
                  </li>
                ))}
              </ul>
            </Col>
          </Row>
          <div className="tab-content">
            {posts.map((post, index) => {
              return index === active ? (
                <div
                  className="tab-pane fade show active"
                  id={`tes_tab_${index}`}
                  key={index}
                >
                  <Row
                    className={`${
                      0 === index % 1 ? " " : "flex-lg-row-reverse"
                    }`}
                  >
                    <Col className='col-tab-img' lg={5}>
                      <div css={imgTab}><img src={post.img} alt='/' /></div>
                    </Col>
                    <Col lg={7}>
                      <div css={contentBox}>
                        <h2>{post.titleContent}</h2>
                        <h4>{post.tagContent}</h4>
                        <h3>{post.subTitleContent}</h3>
                        <p>{post.contentL1}</p>
                        <p>{post.contentL2}</p>
                        <p>{post.contentL3}</p>
                      </div>
                    </Col>
                  </Row>
                </div>
              ) : null;
            })}
          </div>
        </Container>
      </div>
    </section>
  );
};

export default TurmasTabs;
