/** @jsx jsx */
import { Swiper, SwiperSlide } from "swiper/react";
import { jsx } from "@emotion/react";
import "swiper/swiper-bundle.min.css";
import SwiperCore, { Pagination } from "swiper";
import ImgSlider1 from '../assets/images/lp/slider/slider-1.png'
import ImgSlider2 from '../assets/images/lp/slider/slider-2.png'

import {
  sliderHome
} from "../assets/styles/SliderHome.styles";

SwiperCore.use([Pagination]);

const SliderHome = () => {
  const carouselOptions = {
    spaceBetween: 0,
    loop: true,
    slidesPerView: 1,
    Autoplay: true,
    pagination: {
      el: "#team-carousel-pagination",
      type: "bullets",
      clickable: true,
    },
    breakpoints: {
      0: {
        spaceBetween: 0,
        slidesPerView: 1,
        slidesPerGroup: 1,
      },
      576: {
        spaceBetween: 30,
        slidesPerView: 2,
        slidesPerGroup: 2,
      },
      992: {
        spaceBetween: 30,
        slidesPerView: 3,
        slidesPerGroup: 3,
      },
      1200: {
        spaceBetween: 16,
        slidesPerView: 2,
        slidesPerGroup: 2,
      },
    },
  };

  return (
    <section css={sliderHome}>
      <Swiper {...carouselOptions}>
        <SwiperSlide>
          <div>
            <img src={ImgSlider1} style={{ marginRight: 12, width: '100%' }} />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div>
            <img src={ImgSlider2} style={{ marginRight: 12, width: '100%' }} />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div>
            <img src={ImgSlider1} style={{ marginRight: 12, width: '100%' }} />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div>
            <img src={ImgSlider2} style={{ marginRight: 12, width: '100%' }} />
          </div>
        </SwiperSlide>
      </Swiper>
    </section>
  );
};

export default SliderHome;
