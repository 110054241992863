/** @jsx jsx */
import { css } from '@emotion/react'

export const boxContent = css`
  padding: 34px 0px;
  font-family: "kallisto", sans-serif;
  background: #111111;
  @media (min-width: 320px) and (max-width: 767px) {
    padding: 24px 0px;
  }

  @media (min-width: 481px) and (max-width: 767px) {
    padding: 24px 0px;
  }

  @media (min-width: 768px) and (max-width: 990px) {
    padding: 24px 0px;
  }
  /*@media (min-width: 991px) and (max-width: 1199px) {
  }

  @media (min-width: 1200px) and (max-width: 3600px) {
  } */
`

export const textTransition = css`
  display: flex;
  h2 {
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    color: #ffffff;
    font-variation-settings: "wght" 130, "wght" 700;
    margin-bottom: 0px !important;
  }
  img {
      margin-right: 24px;
      margin-left: 24px;
    }
`

export const DDesktop = css` 
  @media (min-width: 320px) and (max-width: 767px) {
    display: none;
  }

  @media (min-width: 481px) and (max-width: 767px) {
    display: none;
  }

  @media (min-width: 768px) and (max-width: 990px) {
    display: none;
  }

  @media (min-width: 991px) and (max-width: 1199px) {
    display: inline;
  }

  @media (min-width: 1200px) and (max-width: 1600px) {
    display: inline;
  }
`

export const DMobile = css` 
  @media (min-width: 320px) and (max-width: 767px) {
    display: flex;
  }

  @media (min-width: 481px) and (max-width: 767px) {
    display: flex;
  }

  @media (min-width: 768px) and (max-width: 990px) {
    display: flex;
  }

  @media (min-width: 991px) and (max-width: 1199px) {
    display: none;
  }

  @media (min-width: 1200px) and (max-width: 3600px) {
    display: none;
  }
`
