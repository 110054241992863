/** @jsx jsx */
import { Col, Container, Row } from "react-bootstrap";
import { jsx } from '@emotion/react'
import { Fragment } from "react";

import { 
  boxContent,
}  from '../assets/styles/TurmasHome.styles'

const TurmasHomeSection = () => {
  return (
    <Fragment>
      <section css={boxContent}>
        <Container>
          <Row>
            <Col lg={10} md={10} sm={12}>
              <h2>Turmas</h2>
              <p>No Bertoni Pro, temos turmas exclusivas para potencializar o conhecimento em áreas específicas, capacitando e inspirando nossos alunos a alcançarem seu potencial máximo.</p>
            </Col>
          </Row>
        </Container>
      </section>
    </Fragment>
  );
};

export default TurmasHomeSection;
