/** @jsx jsx */
import { css } from '@emotion/react'

export const turmasHeader = css`
  background: #f9f9f9;
  padding-bottom: 80px;

  .col-tab-img {
    @media (max-width: 500px) {
      padding: 0px;
    }
  }

  .tabs-ul {
    @media (max-width: 500px) {
      display: flex !important;
      flex-direction: row !important;
      flex-wrap: wrap;
      gap: 5px;
    }
  }
`

export const imgTab = css`
  margin-top: -55px;
  img {
    max-width: 404px;
  }
  @media (min-width: 320px) and (max-width: 767px) {
    margin-top: 0px;
  }

  @media (min-width: 481px) and (max-width: 767px) {
    margin-top: 0px;
  }

  @media (min-width: 768px) and (max-width: 990px) {
    margin-top: 0px;
  }
`

export const chooseUsTitle = css`
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  :first-child li {
    margin-right: 16px;
  }
  li {
    list-style: none;
    margin: 0;
    padding: 0;
    width: auto;
    display: inline-block;
    position: relative;
    &:hover,
    &.active {
      a {
        background: #72c71c;
        color: #f9f9f9;
        font-family: Montserrat;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 27px */
        letter-spacing: 0.5px;
        text-decoration: none;
        img {
          filter: brightness(0) invert(1);
        }
      }
    }

    a {
      display: flex;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #f2f2f2;
      width: auto;
      height: 42px;
      /* padding-left: 24px; */
      color: #111111;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%; /* 27px */
      letter-spacing: 0.5px;
      border: none;
      margin-bottom: 12px;
      padding: 8px;
      img {
        margin-right: 8px;
        /* filter: invert(100%) sepia(61%) saturate(0%) hue-rotate(317deg) brightness(107%) contrast(101%); */
      }
    }
  }
  @media (min-width: 320px) and (max-width: 767px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    :first-child li {
      margin-right: 0px;
    }
    li {
      a {
        width: 100%;
      }
    }
  }

  @media (min-width: 481px) and (max-width: 767px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    li {
      a {
        width: 100%;
      }
    }
  }

  @media (min-width: 768px) and (max-width: 990px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    li {
      a {
        width: 100%;
      }
    }
  }
`

export const contentBox = css`
  position: relative;
  margin-top: 32px;
  h2 {
    font-family: 'kallisto', sans-serif;
    color: #000000;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
  }
  h3 {
    color: #5b5b5b;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    letter-spacing: 0.5px;
    margin-bottom: 32px;
  }
  h4 {
    color: #5b5b5b;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    letter-spacing: 0.5px;
    margin-bottom: 32px;
  }
  p {
    color: #111111;
    /* Desktop/Paragraph 2 */
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    :not(:last-child) {
      margin-bottom: 8px;
    }
  }
  @media (min-width: 320px) and (max-width: 767px) {
  }

  @media (min-width: 481px) and (max-width: 767px) {
  }

  @media (min-width: 768px) and (max-width: 990px) {
  }
`

export const btnBox = css`
  display: flex;
  align-items: center;
  font-family: 'kallisto', sans-serif;
  margin-top: 40px;
  a:first-child {
    font-weight: 700;
    font-size: 16px;
    line-height: 120%;
    text-align: center;
    color: #ffffff;
    font-variation-settings: 'wght' 700;
    background: #00854e;
    width: 212px;
    height: 52px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 32px;
    :hover {
      color: #ffffff;
      background: #312e2d;
      text-decoration: none;
    }
    :active {
      background: #f7ff53;
      color: #312e2d;
    }
  }
  a {
    font-weight: 700;
    font-size: 16px;
    line-height: 120%;
    text-align: center;
    color: #312e2d;
    font-variation-settings: 'wght' 700;
    img {
      margin-left: 8px;
    }
    :hover {
      color: #312e2d;
      text-decoration: none;
    }
    :active {
      color: #f7ff53;
      text-decoration: none;
    }
  }
  @media (min-width: 320px) and (max-width: 767px) {
    display: flex;
    flex-direction: column;
    a {
      display: flex;
      align-items: center;
      img {
        display: flex;
        align-items: center;
        margin-bottom: 0px;
        width: initial;
      }
    }
    a:first-child {
      margin-right: 0px;
      margin-bottom: 32px;
    }
  }

  @media (min-width: 481px) and (max-width: 767px) {
    display: flex;
    flex-direction: column;
    a:first-child {
      margin-right: 0px;
    }
  }

  @media (min-width: 768px) and (max-width: 990px) {
    display: flex;
    flex-direction: column;
    a:first-child {
      margin-right: 0px;
    }
  }
`
