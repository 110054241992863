/** @jsx jsx */
import { css } from '@emotion/react'

export const sliderHeader = css`
  font-family: "kallisto", sans-serif;
  h1 {
    font-weight: 700;
    font-size: 40px;
    line-height: 120%;
    color: #ECECEC;
    font-variation-settings: "wght" 700;
    width: 100%;
    max-width: 600px;
  }
  p {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 150%;
    color: #ffffff;
    margin-bottom: 32px;
    width: 100%;
    max-width: 600px;
    text-transform: none;
    letter-spacing: 0.5px;
  }
  a {
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    text-transform: uppercase;
    text-align: center;
    color: #ffffff;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.40) 100%), rgba(255, 255, 255, 0.16);
    width: 220px;
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
    :hover {
      color: #000000;
      background: #A0FF01;
      text-decoration: none;
    }
  }
  @media (min-width: 320px) and (max-width: 767px) {
    h1 {
      font-size: 32px;
    }
  }
  @media (min-width: 481px) and (max-width: 767px) {
    h1 {
      font-size: 32px;
    }
  }
  @media (min-width: 768px) and (max-width: 990px) {
    h1 {
      font-size: 32px;
    }
  }
`

