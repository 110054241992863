/** @jsx jsx */
import { css } from '@emotion/react'

export const contatosHome = css` 
  background: #F9F9F9;
  padding: 72px 0;
  font-family: "kallisto", sans-serif;
`

export const formVisitas = css` 
  border: 1px solid #D4D4D4;
  padding: 52px;
  h2 {
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    margin-bottom: 8px;
    color: #000000;
    font-variation-settings: "wght" 130, "wght" 700;
    width: 100%;
  }
  p {
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    color: #000000;
    margin-bottom: 24px;
  }
  form {
    font-family: 'Montserrat', sans-serif;
    input {
      border: 1px solid #D4D4D4;
      width: 100%;
      height: 52px;
      background: #FAFAFA;
      font-weight: 400;
      font-size: 18px;
      line-height: 120%;
      color: #7C7C7C !important;
      padding-left: 24px;
      padding-right: 24px;
    }
    input[type="datetime-local"]::-webkit-calendar-picker-indicator {
    opacity: 0.4;
}
    select {
      border: 1px solid #D4D4D4;
      width: 100%;
      height: 52px;
      background: #FAFAFA;
      padding-left: 24px;
      padding-right: 24px;
      font-weight: 400;
      font-size: 18px;
      line-height: 120%;
      color: #7C7C7C !important;
    }
  }
  @media (min-width: 320px) and (max-width: 767px) {
    padding: 32px;
    margin-bottom: 16px;
    h2 {
      text-align: left;
    }
    p {
      text-align: left;
    }
  }
  @media (min-width: 481px) and (max-width: 767px) {
    padding: 32px;
    margin-bottom: 16px;
    h2 {
      text-align: left;
    }
    p {
      text-align: left;
    }
  }
  @media (min-width: 768px) and (max-width: 990px) {
    padding: 32px;
    margin-bottom: 16px;
    h2 {
      text-align: left;
    }
    p {
      text-align: left;
    }
  }
`

export const btnEnviar = css`
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  text-align: center;
  color: #ffffff;
  font-variation-settings: "wght" 700;
  background: #75C721;
  width: 100%;
  max-width: 322px;
  height: 52px;
  border: none;
  margin-top: 68px;
  :hover {
    color: #ffffff;
    border: 1px solid #996161;
    background: #000000;
  }
  @media (min-width: 320px) and (max-width: 767px) {
    margin-top: auto;
  }
  @media (min-width: 481px) and (max-width: 767px) {
    margin-top: auto;
  }
  @media (min-width: 768px) and (max-width: 990px) {
    margin-top: auto;
  }
`

export const formContatos = css` 
  border: 1px solid #D4D4D4;
  padding: 52px;
  h2 {
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    margin-bottom: 8px;
    color: #000000;
    font-variation-settings: "wght" 130, "wght" 700;
    width: 100%;
  }
  p {
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    color: #000000;
    margin-bottom: 24px;
  }
  form {
    font-family: 'Montserrat', sans-serif;
    input {
      border: 1px solid #D4D4D4;
      width: 100%;
      height: 52px;
      background: #FAFAFA;
      font-weight: 400;
      font-size: 18px;
      line-height: 120%;
      color: #7C7C7C !important;
      padding-left: 24px;
      padding-right: 24px;
    }
    textarea {
      width: 100%;
      background: #FAFAFA;
      font-weight: 400;
      font-size: 18px;
      line-height: 120%;
      color: #7C7C7C !important;
      padding-left: 24px;
      padding-right: 24px;
      padding-top: 10px;
      margin-bottom: 13px;
      border: 1px solid #D4D4D4;
    }
  }
  @media (min-width: 320px) and (max-width: 767px) {
    padding: 32px;
    h2 {
      text-align: left;
    }
    p {
      text-align: left;
    }
  }
  @media (min-width: 481px) and (max-width: 767px) {
    padding: 32px;
    h2 {
      text-align: left;
    }
    p {
      text-align: left;
    }
  }
  @media (min-width: 768px) and (max-width: 990px) {
    padding: 32px;
    h2 {
      text-align: left;
    }
    p {
      text-align: left;
    }
  }
`

export const btnEnviar2 = css`
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  text-align: center;
  color: #ffffff;
  font-variation-settings: "wght" 700;
  background: #75C721;
  width: 100%;
  max-width: 322px;
  height: 52px;
  border: none;
  :hover {
    color: #ffffff;
    border: 1px solid #996161;
    background: #000000;
  }
`
