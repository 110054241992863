/** @jsx jsx */
import { Col, Container, Row } from "react-bootstrap";
import { jsx } from '@emotion/react'
import { Fragment } from "react";

import { 
  estatisticaContent,
}  from '../assets/styles/EstatisticaBertoni.styles'

const EstatisticasBertoni = () => {
  return (
    <Fragment>
      <section css={estatisticaContent}>
        <Container>
          <Row>
            <Col lg={4} md={4} sm={12}>
              <div>
                <h2>500+</h2>
                <p>aprovações de alunos em <span>Medicina</span></p>
              </div>
            </Col>
            <Col lg={4} md={4} sm={12}>
              <div>
                <h2>1° Lugar</h2>
                <p>Região Sul no simulado independente <span>EVO 2023</span></p>
              </div>
            </Col>
            <Col lg={4} md={4} sm={12}>
              <div>
                <h2>900+</h2>
                <p>mais de 60 redações acima de 900 no <span>ENEM 2022</span></p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Fragment>
  );
};

export default EstatisticasBertoni;
