/** @jsx jsx */
import { Col, Container, Row } from "react-bootstrap";
import { jsx } from '@emotion/react'
import { Fragment } from "react";
import { Link } from "gatsby";
import UnidadeCascavel from '../assets/images/lp/home2/unidade-cascavel.png'
import UnidadeFoz from '../assets/images/lp/home2/unidade-foz.png'
import UnidadePOA from '../assets/images/lp/home2/unidade-poa.png'

import { 
  unidadesSection,
  boxUnidades,
}  from '../assets/styles/UnidadesHome.styles'

const UnidadesHome = () => {
  return (
    <Fragment>
      <section css={unidadesSection}>
        <Container>
          <Row>
            <Col lg={4} md={4} sm={12}>
              <div css={boxUnidades}>
                <img src={UnidadeCascavel} alt='/' />
                <h4>Cascavel, PR</h4>
                <p>Rua Castro Alves, 2260</p>
                <Link to="/unidade-cascavel">VEJA MAIS</Link>
              </div>
            </Col>
            <Col lg={4} md={4} sm={12}>
              <div css={boxUnidades}>
                <img src={UnidadeFoz} alt='/' />
                <h4>Foz do Iguaçu, PR</h4>
                <p>Rua Santos Dumont, 460</p>
                <Link to="/unidade-foz-do-iguacu">VEJA MAIS</Link>
              </div>
            </Col>
            <Col lg={4} md={4} sm={12}>
              <div css={boxUnidades}>
                <img src={UnidadePOA} alt='/' />
                <h4>Porto Alegre, RS</h4>
                <p>Rua Auxiliadora, 191</p>
                <Link to="/unidade-porto-alegre">VEJA MAIS</Link>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Fragment>
  );
};

export default UnidadesHome;
